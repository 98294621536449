<template>
    <div class="search-view l-stack">
        <!-- Search -->
        <div class="l-padded u-bb">
            <SearchField v-model="search" />
        </div>

        <div v-if="username" class="l-padded u-bb">
            <strong>{{ $t('welcome', { username }) }}</strong>
        </div>

        <template v-if="search == ''">
            <ListHeader>
                <div slot="title">
                    {{ $t('shared.assets') }}
                </div>

                <div slot="accessory">
                    <!--<router-link :to="'/map/create-asset'">
                        <IconButton :name="'add-circle'" :size="24" :title="$t('addTracker')"/>
                    </router-link>-->
                </div>
            </ListHeader>

            <List>
                <router-link :to="{ path: '/map/assets' }">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <trip-multiple-destinations-icon
                                width="24"
                                height="24"
                            />

                            <span>
                                {{
                                    $t('allItems', {
                                        items: $t('shared.assets'),
                                    })
                                }}
                            </span>
                        </div>

                        <span class="list-item__count">
                            {{ trackers.length }}
                        </span>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="isAlptracker"
                    :to="{
                        path: '/map/assets',
                        query: { recent: true },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <recent-icon width="24" height="24" color="black" />

                            <span>
                                {{ $t('recentlyActive') }}
                            </span>
                        </div>

                        <span class="list-item__count">
                            {{ activeInLast6HoursCount }}
                        </span>
                    </ListItem>
                </router-link>

                <router-link
                    :to="{ path: '/map/assets', query: { alert: true } }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <check-icon
                                v-if="alertAssetsCount === 0"
                                width="24"
                                height="24"
                                color="#41b883"
                            />
                            <warning-icon v-else width="24" height="24" />

                            <span>
                                {{
                                    $t('hasAlert', {
                                        items: $t('shared.assets'),
                                    })
                                }}
                            </span>
                        </div>

                        <span class="list-item__count">
                            {{ alertAssetsCount }}
                        </span>
                    </ListItem>
                </router-link>

                <router-link v-if="showLeftZone" :to="'/map/lost-assets'">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <cursor-move-target-right-icon
                                width="24"
                                height="24"
                            />

                            <span>{{ $t('leftZone') }}</span>
                        </div>

                        <span class="list-item__count">
                            {{ lostAssetsCount }}
                        </span>
                    </ListItem>
                </router-link>

                <router-link :to="{ path: '/map/low-battery-assets' }">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <battery-icon width="24" height="24" :warn="true" />

                            <span>{{ $t('lowBattery') }}</span>
                        </div>

                        <span class="list-item__count">
                            {{ lowBatteryAssetsCount }}
                        </span>
                    </ListItem>
                </router-link>
            </List>
            <ListHeader>
                <div slot="title">{{ $t('location') }}</div>
                <div slot="accessory">
                    <router-link :to="'/map/location/create'">
                        <icon-button>
                            <add-icon
                                width="24"
                                height="24"
                                :name="$t('router.createlocation')"
                            />
                        </icon-button>
                    </router-link>
                </div>
            </ListHeader>

            <List>
                <router-link
                    v-for="location in getLocationsSortedByName"
                    :key="'loc' + location.id"
                    :to="{ path: 'map/location/' + location.id }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <pin-location-icon width="24" height="24" />

                            <span>{{ location.name }}</span>
                        </div>

                        <span class="list-item__count">
                            {{ locationAssetsCounts[location.id] }}
                        </span>
                    </ListItem>
                </router-link>
            </List>

            <div v-if="isAlploraUser" class="u-bb">
                <router-link
                    :to="{
                        name: 'globalAnimalActivityChart',
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <activity-icon width="24" height="24" />

                            <span>{{ $t('showActivity') }}</span>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>
            </div>

            <transition name="slide-from-right">
                <div v-if="isChartViewActive" class="detached-panel">
                    <div
                        class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                    >
                        {{ detachedPanelTitle }}

                        <router-link to=".">
                            <icon-button @click="$emit('expand')">
                                <remove-icon
                                    width="24"
                                    height="24"
                                    color="black"
                                />
                            </icon-button>
                        </router-link>
                    </div>

                    <div class="detached-panel-body">
                        <router-view />
                    </div>
                </div>
            </transition>
        </template>

        <!-- Search results -->
        <template v-else>
            <List>
                <router-link
                    v-for="item in filteredItems"
                    :key="'router' + item.vueKey"
                    :to="item.path"
                >
                    <ListItem :key="item.vueKey" class="u-bb">
                        <div class="l-stack l-gap-1">
                            <div>
                                <strong>{{ item.name }}</strong>
                            </div>

                            <div>{{ $t(item.type) }}</div>
                        </div>
                    </ListItem>
                </router-link>
            </List>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import ActivityIcon from '../icons/ActivityIcon'
import AddIcon from '../icons/AddIcon'
import BatteryIcon from '../icons/BatteryIcon'
import CursorMoveTargetRightIcon from '../icons/CursorMoveTargetRightIcon'
import IconButton from '../IconButton'
import List from '../List'
import ListHeader from '../ListHeader'
import ListItem from '../ListItem'
import PinLocationIcon from '../icons/PinLocationIcon'
import RecentIcon from '@/components/icons/RecentIcon'
import RemoveIcon from '../icons/RemoveIcon'
import SearchField from '../SearchField'
import TripMultipleDestinationsIcon from '../icons/TripMultipleDestinationsIcon'
import WarningIcon from '@/components/icons/WarningIcon'
import CheckIcon from '@/components/icons/CheckIcon'

export default {
    name: 'SearchView',
    components: {
        CheckIcon,
        WarningIcon,
        ActivityIcon,
        AddIcon,
        BatteryIcon,
        CursorMoveTargetRightIcon,
        IconButton,
        List,
        ListHeader,
        ListItem,
        PinLocationIcon,
        RecentIcon,
        RemoveIcon,
        SearchField,
        TripMultipleDestinationsIcon,
    },
    data() {
        return {
            search: '',
            locationAssetsCounts: {},
        }
    },
    computed: {
        ...mapState('authentication', ['userInfo', 'hasActivityAccess']),
        ...mapState('tracker', ['trackers']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
        ...mapGetters('tracker', [
            'lostAssetsCount',
            'lowBatteryAssetsCount',
            'alertAssetsCount',
            'activeInLast6HoursCount',
        ]),
        detachedPanelTitle() {
            return this.$t(`router.${this.$route.name}`)
        },
        isChartViewActive() {
            return this.$route.name === 'globalAnimalActivityChart'
        },
        filteredItems() {
            if (!this.search) {
                return []
            }

            // Insert fancy search algorithm here…
            const filteredAssets = this.trackers
                .filter(
                    asset =>
                        asset.asset_details.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        asset.deveui
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        asset.asset_details.identification
                            ?.toLowerCase()
                            ?.includes(this.search.toLowerCase())
                )
                .map(asset => ({
                    id: asset.id,
                    vueKey: 'str' + asset.id,
                    name: asset.asset_details.name,
                    type: 'Asset',
                    path: 'map/assets/' + asset.id,
                }))

            const filteredLocations = this.getLocationsSortedByName
                .filter(location =>
                    location.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                )
                .map(location => ({
                    id: location.id,
                    vueKey: 'sloc' + location.id,
                    name: location.name,
                    type: 'location',
                    path: 'map/location/' + location.id,
                }))

            return [...filteredAssets, ...filteredLocations]
        },
        isAlploraUser() {
            return this.userInfo.platform === 'alplora'
        },
        username() {
            const {
                first_name: firstName,
                last_name: lastName,
                username,
            } = this.userInfo

            return `${firstName} ${lastName}`.trim() || username || ''
        },
        showLeftZone() {
            return process.env.VUE_APP_ENVIRONMENT_NAME !== 'orkanet'
        },
        isAlptracker() {
            return process.env.VUE_APP_ENVIRONMENT_NAME === 'alptracker'
        },
    },
    beforeMount() {
        this.calculateLocationAssetsCounts()
    },
    methods: {
        calculateLocationAssetsCounts() {
            this.locationAssetsCounts = this.getLocationsSortedByName.reduce(
                (counts, location) => {
                    counts[location.id] = this.trackers.filter(
                        asset => asset.location === location.id
                    ).length
                    return counts
                },
                {}
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "addTracker": "Add tracker",
        "allItems": "All {items}",
        "leftZone": "Left zone",
        "location": "Location",
        "lowBattery": "Low battery",
        "recentlyActive": "Active in the last 6 hours",
        "tracker": "Tracker",
        "welcome": "Welcome, {username}!",
        "showActivity": "Show activity",
        "hasAlert": "{items} with alert"
    },
    "de": {
        "addTracker": "Asset Hinzufügen",
        "allItems": "Alle {items}",
        "leftZone": "Zone verlassen",
        "location": "Standort",
        "lowBattery": "Niedriger Batteriestand",
        "recentlyActive": "Aktiv in den letzten 6 Stunden",
        "tracker": "Tracker",
        "welcome": "Willkommen, {username}!",
        "showActivity": "Aktivität anzeigen",
        "hasAlert": "{items} mit Alarm"
    },
    "it": {
        "addTracker": "Asset agguingere",
        "allItems": "Tutti {items}",
        "leftZone": "Escono Zona",
        "location": "Locazione",
        "lowBattery": "Basso Batteria",
        "recentlyActive": "Attivo nelle ultime 6 ore",
        "tracker": "Tracker",
        "welcome": "Benvenuto, {username}!",
        "showActivity": "Mostra attività",
        "hasAlert": "{items} con allarme"
    }
}
</i18n>

<style lang="scss" scoped>
.search-view > * + * {
    border-bottom: $style-border;
}
</style>
